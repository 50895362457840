import axios from "axios";
import OpenAI from "openai";

const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

export const getOpenAICompletion = async (prompt) => {
    const completion = await openai.chat.completions.create({
        model: "gpt-4o-mini",
        messages: [
            {
                role: "user",
                content: prompt,
            },
        ],
    });

    return completion.choices[0].message;
};

export const getOpenAICompletionFromAssistant = async (prompt, assistantId, apiKey) => {
    let threadId = "";
    const message = prompt;
    const createThread = async () => {
        const url = "https://api.openai.com/v1/threads";

        const res = await axios.post(url, {
            messages: [
                {
                    role: "user",
                    content: message,
                },
            ],
        }, {
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json",
                "OpenAI-Beta": "assistants=v2"
            }
        });

        return res.data.id;
    }

    const createRun = async (threadId) => {
        const url = `https://api.openai.com/v1/threads/${threadId}/runs`;

        const res = await axios.post(url, {
            assistant_id: assistantId,

        }, {
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json",
                "OpenAI-Beta": "assistants=v2"
            }
        });

        return res.data.id;
    };

    const retrieveRun = async (threadId, runId) => {
        const url = `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`;

        const res = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json",
                "OpenAI-Beta": "assistants=v2"
            }
        });

        return res
    };

    const listMessages = async (threadId) => {
        const url = `https://api.openai.com/v1/threads/${threadId}/messages`;

        const res = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${apiKey}`,
                "Content-Type": "application/json",
                "OpenAI-Beta": "assistants=v2"
            }
        });

        return res.data;
    }

    const getResponse = async (threadId, runId) => {
        while (true) {
            const res = await retrieveRun(threadId, runId);
            console.log(res);

            if (res.data.status === "completed") {
                break;
            }

            await new Promise(r => setTimeout(r, 2500));
        }

        const messages = await listMessages(threadId);
        return messages.data[0].content[0].text.value;

    };



    threadId = await createThread();
    const runId = await createRun(threadId);
    const response = await getResponse(threadId, runId);

    return response;


};

export const getOpenAIAssistants = async (apiKey) => {
    const url = "https://api.openai.com/v1/assistants";

    const res = await axios.get(url, {
        headers: {
            "Authorization": `Bearer ${apiKey}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v2"
        }
    });

    return res.data.data;
};
