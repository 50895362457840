import React, { useState, useEffect, useRef } from "react";
import { useUser } from "../contexts/UserContext";
import { useLocation } from "react-router-dom";
import {
    TextField,
    Container,
    Button,
    Box,
    Typography,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { updateUserData } from "../firebase/auth";
import { checkBearerToken } from "../utils/google/gapi";
import { getOpenAIAssistants } from "../utils/openai/openaiapi";

const SCOPES = "https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/documents";

function Profile() {
    const [profileUser, setProfileUser] = useState(null);

    const { user, setUserContext } = useUser();
    const googleButtonRef = useRef(null);
    const { state } = useLocation();

    useEffect(() => {
        if (state?.from === "oauth2") {
            const token = state.token;
            console.log("Token:", token);

            setUserContext({ GOOGLE_BEARER_TOKEN: token });
        }
    }, [state, setUserContext]);

    useEffect(() => {
        if (user?.uid) {
            setProfileUser(user);
        }
    }, [user]);

    const handleSaveUser = async () => {
        try {
            await updateUserData(user.uid, profileUser);
            alert("User data saved successfully");
        } catch (error) {
            console.error("Error saving user data", error);
            alert("Error saving user data");
        }
    };

    const handleGoogleLogin = () => {
        const oauthEndpoint = "https://accounts.google.com/o/oauth2/v2/auth";

        if (!profileUser.GOOGLE_CLIENT_KEY) {
            alert("Please enter a Google Client Key");
            return;
        }

        const params = {
            client_id: profileUser.GOOGLE_CLIENT_KEY,
            redirect_uri: "https://ai-content-bot-e3381.web.app/oauth2",
            response_type: "token",
            scope: SCOPES,
            include_granted_scopes: 'true',
            state: "pass-through value",
        };

        const queryString = Object.keys(params)
            .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
            .join('&');
        const url = `${oauthEndpoint}?${queryString}`;
        window.location.href = url;
    };

    const handleCheckBearerToken = async () => {
        try {
            const response = await checkBearerToken(profileUser.GOOGLE_BEARER_TOKEN, true);
            if (response) alert("Token is valid");
        } catch (error) {
            console.log(error);
            if (error.response?.data.error === "invalid_token") {
                alert("Token is invalid");
                googleButtonRef.current.click();
            } else {
                alert("Error checking token");
            }
        }
    };

    const handleReloadAssistants = async () => {
        try {
            const assistants = await getOpenAIAssistants(profileUser.OPEN_API_KEY);
            // set only assistants name and ids to user, wait 1 second and launch handleSaveUser
            setProfileUser({ OPEN_API_ASSISTANTS: assistants });
            setTimeout(() => {
                setUserContext({ OPEN_API_ASSISTANTS: assistants });
                handleSaveUser()
            }, 1000);
        } catch (error) {
            console.error("Error reloading assistants", error);
            alert("Error reloading assistants");
        }


    };


    return (
        <Container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {profileUser && (
                <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
                    <Typography variant="h4" align="center" gutterBottom>
                        Profile Settings
                    </Typography>
                    <Box component="form" noValidate autoComplete="off">
                        <TextField
                            label="OpenAI API Key"
                            value={profileUser?.OPEN_API_KEY}
                            onChange={(e) => setProfileUser({ ...profileUser, OPEN_API_KEY: e.target.value })}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            placeholder="Enter your OpenAI API Key"
                        />
                        <TextField
                            label="Google Client Key"
                            value={profileUser?.GOOGLE_CLIENT_KEY}
                            onChange={(e) => setProfileUser({ ...profileUser, GOOGLE_CLIENT_KEY: e.target.value })}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            placeholder="Google Client Key"
                        />
                        <TextField
                            label=""
                            value={profileUser?.GOOGLE_BEARER_TOKEN}
                            disabled
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            placeholder="Login with Google to get token"
                        />
                        <Typography variant="body2" color="red" align="center">
                            You must login with Google to change the bearer token
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                            <Button
                                variant="contained"
                                onClick={handleGoogleLogin}
                                ref={googleButtonRef}
                            >
                                Login to Google
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleCheckBearerToken}
                            >
                                Check Bearer Token
                            </Button>
                        </Box>
                        <FormControl fullWidth margin="normal" variant="outlined" sx={{ mt: 3 }}>
                            <InputLabel>Select Assistant</InputLabel>
                            <Select
                                value={profileUser?.SELECTED_ASSISTANT}
                                onChange={(e) => setProfileUser({ ...profileUser, SELECTED_ASSISTANT: e.target.value })}
                                label="Select Assistant"
                            >
                                {profileUser?.OPEN_API_ASSISTANTS?.map((assistant) => (
                                    <MenuItem key={assistant.id} value={assistant.id}>
                                        {assistant.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => handleReloadAssistants()}
                            sx={{ mt: 2 }}
                        >
                            Reload Assistants
                        </Button>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleSaveUser}
                            sx={{ mt: 2 }}
                        >
                            Save Settings
                        </Button>
                    </Box>
                </Box>
            )}
        </Container>
    );
}

export default Profile;
